import React, { useState } from 'react';
import { Box, Grid, Paper, Typography, IconButton, Menu, MenuItem, List, ListItem, ListItemIcon, ListItemText, Avatar, Chip, Tooltip, Divider } from '@mui/material';
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip as ChartTooltip, Legend, ResponsiveContainer, ScatterChart, Scatter, CartesianGrid, XAxis, YAxis, ZAxis, Cell
} from 'recharts';
import { CheckCircle as CheckCircleIcon, PlayArrow as PlayIcon, Pause as PauseIcon, Cancel as CancelIcon, FilterList as FilterListIcon, Email, LabelImportant, Assignment, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const barData = [
  { name: 'Produto A', vendas: 30 },
  { name: 'Produto B', vendas: 45 },
  { name: 'Produto C', vendas: 20 },
];

const lineData = [
  { name: 'Janeiro', vendas: 30 },
  { name: 'Fevereiro', vendas: 40 },
  { name: 'Março', vendas: 35 },
  { name: 'Abril', vendas: 50 },
];

const pieData = [
  { name: 'Produto A', value: 400 },
  { name: 'Produto B', value: 300 },
  { name: 'Produto C', value: 300 },
];

const radarData = [
  { subject: 'Força', A: 120, B: 110 },
  { subject: 'Velocidade', A: 98, B: 130 },
  { subject: 'Resistência', A: 86, B: 130 },
  { subject: 'Agilidade', A: 99, B: 100 },
  { subject: 'Inteligência', A: 85, B: 90 },
];

const scatterData = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];

const taskList = [
  { id: 1, titulo: 'Finalizar relatório financeiro', descricao: 'Atualizar dados do último mês', status: 'pendente' },
  { id: 2, titulo: 'Reunião com equipe de marketing', descricao: 'Discutir estratégia para o próximo trimestre', status: 'concluida' },
  { id: 3, titulo: 'Atualizar planilha de vendas', descricao: 'Inserir os dados de vendas de março', status: 'em_execucao' },
];

const emailMessages = [
  { id: 1, from: 'Suporte', subject: 'Atualização de sistema', preview: 'Nova versão disponível para download', tag: 'Suporte' },
  { id: 2, from: 'Vendas', subject: 'Proposta Comercial', preview: 'Confira nossa nova proposta...', tag: 'Vendas' },
  { id: 3, from: 'Financeiro', subject: 'Fatura Paga', preview: 'A fatura foi paga com sucesso.', tag: 'Financeiro' },
];

const statusChips = {
  'pendente': <Chip icon={<PlayIcon />} label="Pendente" color="warning" />,
  'em_execucao': <Chip icon={<PauseIcon />} label="Em Execução" color="info" />,
  'concluida': <Chip icon={<CheckCircleIcon />} label="Concluída" color="success" />,
  'cancelada': <Chip icon={<CancelIcon />} label="Cancelada" color="error" />,
};

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Abre o menu de filtros
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Fecha o menu de filtros
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: 'bold', color: '#424242' }}>
          Dashboard Analítico
        </Typography>

        <IconButton onClick={handleFilterClick}>
          <FilterListIcon sx={{ fontSize: '24px', color: '#424242' }} />
        </IconButton>
      </Box>

      {/* Menu de Filtros */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Filtro 1</MenuItem>
        <MenuItem onClick={handleClose}>Filtro 2</MenuItem>
        <MenuItem onClick={handleClose}>Filtro 3</MenuItem>
      </Menu>

      <Grid container spacing={4}>
        {/* Gráficos */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
              Vendas por Produto
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={barData}>
                <Bar dataKey="vendas" fill="#42a5f5" />
                <ChartTooltip />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Gráfico de Linhas */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
              Vendas Mensais
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={lineData}>
                <Line type="monotone" dataKey="vendas" stroke="#42a5f5" />
                <ChartTooltip />
                <Legend />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Lista de Tarefas - Estilo Google Tasks */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px' }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
              Lista de Tarefas
            </Typography>
            <Grid container spacing={2}>
              {taskList.map((task) => (
                <Grid item xs={12} key={task.id}>
                  <Paper
                    sx={{
                      padding: '16px',
                      backgroundColor: '#fff',
                      borderRadius: 2,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)' },
                      cursor: 'pointer',
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{task.titulo}</Typography>
                        <Typography variant="body2" color="textSecondary">{task.descricao}</Typography>
                      </Box>
                      {statusChips[task.status]}
                    </Box>
                    <Box mt={2} display="flex" gap={2} justifyContent="flex-end">
                      <Tooltip title="Editar Tarefa">
                        <IconButton color="primary"><EditIcon /></IconButton>
                      </Tooltip>
                      <Tooltip title="Remover Tarefa">
                        <IconButton color="error"><DeleteIcon /></IconButton>
                      </Tooltip>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Mensagens - Estilo Gmail */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px' }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
              Mensagens
            </Typography>
            <List>
              {emailMessages.map((msg) => (
                <ListItem key={msg.id}>
                  <ListItemIcon>
                    <Email sx={{ color: '#42a5f5' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">{msg.from}</Typography>
                        <Chip label={msg.tag} color="primary" />
                      </Box>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" sx={{ color: '#616161' }}>
                          {msg.subject} - {msg.preview}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
