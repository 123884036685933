import React, { useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Modal, Typography, TextField, Grid, Avatar, Chip, InputAdornment } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit, Visibility, Print, SaveAlt, ViewModule, ViewList, Email, Phone, Search } from '@mui/icons-material';
import jsPDF from 'jspdf'; // Importar jsPDF
import 'jspdf-autotable'; // Importar AutoTable para geração de tabelas no PDF

// Gera dados simulados para 500 clientes
const generateClientesData = (num) => {
  return Array.from({ length: num }).map((_, i) => ({
    id: i + 1,
    nome: `Cliente ${i + 1}`,
    email: `cliente${i + 1}@email.com`,
    telefone: '123-456-789',
    status: i % 2 === 0 ? 'Ativo' : 'Inativo',
  }));
};

const Clientes = () => {
  const [data, setData] = useState(generateClientesData(500));
  const [hasMore, setHasMore] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [viewMode, setViewMode] = useState('table'); // 'table' ou 'cards'
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [filtroStatus, setFiltroStatus] = useState('todos'); // Filtro de status
  const [searchTerm, setSearchTerm] = useState(''); // Termo de pesquisa

  // Filtrar clientes com base no status e no termo de pesquisa
  const filteredData = useMemo(() => {
    let filteredClientes = data;
    if (filtroStatus !== 'todos') {
      filteredClientes = filteredClientes.filter(cliente => cliente.status === filtroStatus);
    }
    if (searchTerm) {
      filteredClientes = filteredClientes.filter(cliente =>
        cliente.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return filteredClientes;
  }, [data, filtroStatus, searchTerm]);

  // Configurando as colunas da tabela
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: true,
        enableSorting: true,
        size: 50,
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
        enableColumnOrdering: true,
        enableSorting: true,
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableColumnOrdering: true,
        enableSorting: true,
        size: 250,
      },
      {
        accessorKey: 'telefone',
        header: 'Telefone',
        enableColumnOrdering: true,
        enableSorting: true,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnOrdering: true,
        enableSorting: true,
        filterVariant: 'select',
        filterSelectOptions: ['Ativo', 'Inativo'],
      },
      {
        header: 'Ações',
        size: 150,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Tooltip arrow title="Visualizar">
              <IconButton color="info" onClick={() => handleView(row)}>
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Editar">
              <IconButton color="primary" onClick={() => handleEdit(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Remover">
              <IconButton color="error" onClick={() => handleRemove(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  // Função para visualizar cliente
  const handleView = (row) => {
    setSelectedCliente(row.original);
    setModalContent('view');
    setModalOpen(true);
  };

  // Função para editar cliente
  const handleEdit = (row) => {
    setSelectedCliente(row.original);
    setModalContent('edit');
    setModalOpen(true);
  };

  // Função para remover cliente
  const handleRemove = (row) => {
    setSelectedCliente(row.original);
    setModalContent('remove');
    setModalOpen(true);
  };

  // Exportar dados como CSV
  const exportCSV = () => {
    const csvData = data.map((cliente) => ({
      ID: cliente.id,
      Nome: cliente.nome,
      Email: cliente.email,
      Telefone: cliente.telefone,
      Status: cliente.status,
    }));

    const csvString = [
      ['ID', 'Nome', 'Email', 'Telefone', 'Status'],
      ...csvData.map(cliente => [
        cliente.ID,
        cliente.Nome,
        cliente.Email,
        cliente.Telefone,
        cliente.Status
      ])
    ]
    .map(e => e.join(","))
    .join("\n");

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "clientes.csv";
    link.click();
  };

  // Exportar dados como PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Clientes', 20, 10);
    doc.autoTable({
      head: [['ID', 'Nome', 'Email', 'Telefone', 'Status']],
      body: data.map((cliente) => [cliente.id, cliente.nome, cliente.email, cliente.telefone, cliente.status]),
    });
    doc.save('clientes.pdf');
  };

  // Função para carregar mais dados ao rolar a página
  const fetchMoreData = () => {
    if (data.length >= 600) {  // Limite máximo para a demonstração
      setHasMore(false);
      return;
    }
    setData((prevData) => [
      ...prevData,
      ...generateClientesData(10).map((cliente, i) => ({
        ...cliente,
        id: prevData.length + i + 1, // Ajusta os IDs ao adicionar mais dados
      })),
    ]);
  };

  // Função para alternar entre visualização em tabela e cards
  const toggleViewMode = () => {
    setViewMode(viewMode === 'table' ? 'cards' : 'table');
  };

  // Conteúdo da modal para visualizar ou editar cliente
  const renderModalContent = () => {
    if (!selectedCliente) return null;

    if (modalContent === 'view') {
      return (
        <>
          <Typography variant="h6">Visualizar Cliente</Typography>
          <Typography><b>ID:</b> {selectedCliente.id}</Typography>
          <Typography><b>Nome:</b> {selectedCliente.nome}</Typography>
          <Typography><b>Email:</b> {selectedCliente.email}</Typography>
          <Typography><b>Telefone:</b> {selectedCliente.telefone}</Typography>
          <Typography><b>Status:</b> {selectedCliente.status}</Typography>
        </>
      );
    }

    if (modalContent === 'edit') {
      return (
        <>
          <Typography variant="h6">Editar Cliente</Typography>
          <TextField fullWidth label="Nome" defaultValue={selectedCliente.nome} sx={{ mb: 2 }} />
          <TextField fullWidth label="Email" defaultValue={selectedCliente.email} sx={{ mb: 2 }} />
          <TextField fullWidth label="Telefone" defaultValue={selectedCliente.telefone} sx={{ mb: 2 }} />
          <Button variant="contained" color="primary" onClick={() => alert('Cliente atualizado!')}>Salvar</Button>
        </>
      );
    }

    if (modalContent === 'remove') {
      return (
        <>
          <Typography variant="h6">Remover Cliente</Typography>
          <Typography>Tem certeza que deseja remover o cliente <b>{selectedCliente.nome}</b>?</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button variant="contained" color="error" onClick={() => alert(`Cliente ${selectedCliente.nome} removido`)}>
              Remover
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(false)}>Cancelar</Button>
          </Box>
        </>
      );
    }
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={viewMode === 'table' ? <ViewModule /> : <ViewList />}
          onClick={toggleViewMode}
        >
          Alternar para {viewMode === 'table' ? 'Cards' : 'Tabela'}
        </Button>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" color="success" onClick={exportCSV} startIcon={<SaveAlt />} sx={{ textTransform: 'none' }}>
            Exportar CSV
          </Button>
          <Button variant="contained" color="info" onClick={exportPDF} startIcon={<SaveAlt />} sx={{ textTransform: 'none' }}>
            Exportar PDF
          </Button>
          <Button variant="contained" color="secondary" onClick={() => window.print()} startIcon={<Print />} sx={{ textTransform: 'none' }}>
            Imprimir
          </Button>
        </Box>
      </Box>

      {/* Barra de pesquisa para o modo de visualização em cards */}
      {viewMode === 'cards' && (
        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            placeholder="Pesquisar por nome ou email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/* Filtros para o modo de visualização em cards */}
      {viewMode === 'cards' && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant={filtroStatus === 'todos' ? 'contained' : 'outlined'}
            onClick={() => setFiltroStatus('todos')}
            sx={{ mr: 2 }}
          >
            Todos
          </Button>
          <Button
            variant={filtroStatus === 'Ativo' ? 'contained' : 'outlined'}
            onClick={() => setFiltroStatus('Ativo')}
            sx={{ mr: 2 }}
          >
            Ativos
          </Button>
          <Button
            variant={filtroStatus === 'Inativo' ? 'contained' : 'outlined'}
            onClick={() => setFiltroStatus('Inativo')}
          >
            Inativos
          </Button>
        </Box>
      )}

      {/* Alternância entre visualização em cards ou tabela */}
      {viewMode === 'table' ? (
        <MaterialReactTable
          columns={columns}
          data={filteredData}
          enableInfiniteScroll
          muiTableBodyRowProps={{
            sx: {
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            },
          }}
          onScrollBottom={fetchMoreData}
          hasMore={hasMore}
        />
      ) : (
        <Grid container spacing={3} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {filteredData.map(cliente => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={cliente.id}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)' },
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: 180
                }}
                onClick={() => handleView({ original: cliente })}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: '#42a5f5', mr: 2 }}>{cliente.nome.charAt(0)}</Avatar>
                  <Typography variant="h6" sx={{ mb: 1 }}>{cliente.nome}</Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Email sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 1, color: '#616161' }} />
                  {cliente.email}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Phone sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 1, color: '#616161' }} />
                  {cliente.telefone}
                </Typography>
                <Chip
                  label={cliente.status}
                  color={cliente.status === 'Ativo' ? 'success' : 'default'}
                  sx={{ alignSelf: 'flex-start', mt: 2 }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Modal para visualizar, editar ou remover cliente */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}>
          {renderModalContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default Clientes;
